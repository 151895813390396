@import "../../../../styling/_base.scss";

.c-rr-view-modal {
    border-radius: 4px;
    display: flex;
    min-width: 600px;
    padding: 24px 32px;
    gap: 12px;

    .c-modal-content {    
        display: flex;
        gap: 12px;
        flex-direction: column;
        flex-grow: 1;

        .c-close-button {
            margin-left: auto;
            margin-top: 24px;
        }

        .c-item-list {
            max-height: 340px;
            overflow-y: auto;
        }

        .c-modal-header {
            margin-bottom: 0;

            .c-icon-cancel {
                margin-left: auto;
            }
    
            .ms-Label {
                font-size: 20px;
            }
        }
    
        .c-modal-row {
            gap: 12px;
    
            .c-modal-field {
                flex: 1;
            }

            .c-modal-large-field {
                flex-grow: 2.5;
            }
        }

        .c-modal-buttons {
            margin-top: 16px;
        }
    }   

    .c-scrollable-container {
        display: flex;
    }

    .c-spinner {
        margin: auto;
    }
}
