@import "../../../../styling/_base.scss";

.c-top-nav {
    width: 100%;
    margin-top: 3px;

    .ms-CommandBar {
        padding-left: 10px !important;
        height: 35px !important;
    }

    .c-top-nav-link {
        @include background-color("background-color");
        @include color("top-nav-link-color");
        border-bottom: 3px solid;
        border-color: transparent;
    }

    .c-top-nav-link:hover {
        @include color("top-nav-link-hover-color");
        @include background-color("background-color");
        border-bottom: 3px solid;
        border-color: transparent;
    }

    .c-top-nav-link.focused {
        @include color("top-nav-link-selected-color");
        @include background-color("background-color");
        border-bottom: 3px solid;
        @include border-color("brand");
    }

    .c-top-nav-link.focused:hover {
        @include color("top-nav-link-selected-color");
        @include background-color("background-color");
        border-bottom: 3px solid;
        @include border-color("chart-color-one");
    }
}
