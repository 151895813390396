@import "../../../../../../../../styling/base";

.c-spinner-padding {
    padding: 4vh 4vh 4vh 4vh;
    width: 100%;
}

.c-contact-appt-details {
    margin: 3vh 0vh 0vh 1vh;

    .c-title {
        padding-bottom: 0.5vh;
    }

    .c-subtitle {
        font-size: $teams-caption-font-size;
        margin-bottom: 2vh;
    }

    .c-condensed-two-column {
        margin-right: 5vw;
    }

    .c-header {
        font-weight: 500;
        padding-bottom: 0.5vh;
    }

    .c-content {
        padding-bottom: 1.5vh;
    }

    .c-extra-buffer {
        padding-bottom: 6vh;
    }

    .c-sub-content {
        font-size: $teams-caption-font-size;
    }
}