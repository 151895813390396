@import "../../../../../styling/base";
.c-timezone-dropdown > div {
    @include background-color("list-item-menu-fluent-ui-cutom");
    @include border-color("borders-color");
}

.c-timezone-dropdown {
    div {
        @include color("primary-text");
    }
}

.c-timezone-dropdown-prefix__menu {
    outline: 1px solid;
    @include outline-color("borders-color");
    @include background-color("background-color");
}

.c-timezone-dropdown-prefix__option {
    //@include border-color("borders-color");
    @include color("primary-text");
}

.c-timezone-dropdown-prefix__option:hover {
    @include background-color("list-item-hover-bgcolor");
    //@include background-color("primary-button-text-hover-color");
}

// Necessary to overwrite default colors for when you move mouse away from dropdown without closing it
// Set to use "hover" color as it differentiates it from the selected color
.c-timezone-dropdown-prefix__option--is-focused {
    //@include background-color("primary-button-text-hover-color");
    @include background-color("list-item-hover-bgcolor");
    @include color("primary-text");
}

.c-timezone-dropdown-prefix__option--is-selected {
    //@include background-color("primary-button-text-hover-color");
    @include background-color("list-item-focus-bgcolor");
}