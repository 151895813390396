@import "../../../../../../styling/base.scss";
@import "../../../../../../styling/teams.scss";

.c-appointment-client-container {
    padding: 15px;

    .c-persona {
        margin-top: 10px;
    }

    .c-detail {
        margin-top: 20px;
    }

    .ms-Button {
        border-radius: 4px;
        padding: 4px;
    }

    .c-header {
        font-weight: 600;
    }
}