@import "../../../styling/base";

.c-title-container {
    .c-logo-color-bkgrd {
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 5px;
        margin-right: 12px;

        .c-logo-only-image {
            width: 100%;
            height: auto;
        }
    }

    .c-title.top {
        font-size: $teams-base-font-size;
        font-weight: 700;
        line-height: 20px;
        margin-top: 7px;
    }

    .c-title.middle {
        font-size: $teams-caption-font-size;
        font-weight: 400;
        line-height: 16px;
        margin-top: 2px;
    }

    .c-title.bottom {
        margin-top: 2px;
    }
}
