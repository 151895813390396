@import "../../../styling/_base.scss";

.c-clienteling-cmdbar {
    box-shadow: 0px 1.2px 3.6px rgba(0, 0, 0, 0.03);
    border-top: 1px solid;
    border-bottom: 1px solid;
    @include border-color("borders-color");
    @include background-color("toolbar-color");
    min-height: 48px;
}

.c-store-picker-panel,
.c-about-panel,
.c-user-settings-panel {
    .c-header-nav {
        padding: 6px;
    }

    .c-panel-header {
        font-weight: bold;
        font-size: 18px;
        line-height: 28px;
        padding-left: 4px;
        @include color("top-nav-link-color");
    }

    .ms-Panel-content {
        padding: 0;
    }

    .c-cancel-button {
        font-size: 20px;
        margin-left: 10px;
    }
}