//Teams Text
//Font-family
$teams-header-font-family: "Segoe UI", "Segoe UI Web (West European)", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
$teams-title-font-family: "Segoe UI", "Segoe UI Web (West European)", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
$teams-base-font-family: "Segoe UI", "Segoe UI Web (West European)", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
$teams-caption-font-family: "Segoe UI", "Segoe UI Web (West European)", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
$teams-xsmall-font-family: "Segoe UI", "Segoe UI Web (West European)", -apple-system, BlinkMacSystemFont, Roboto, "Helvetica Neue", sans-serif;
//Font-size
$teams-header-font-size: 24px;
$teams-title-font-size: 18px;
$teams-subtitle-font-size: 16px;
$teams-base-font-size: 14px;
$teams-caption-font-size: 12px;
$teams-xsmall-font-size: 10px;

//Gradient
$linear-gradient-1: rgba(0, 0, 0, 0);
$linear-gradient-2: rgba(192, 192, 192, 1);

//Box Shadow
$shadow-gradient-1: rgba(0, 0, 0, 0.133);
$shadow-gradient-2: rgba(0, 0, 0, 0.11);

$default-required-color: #cc4a31;
$dark-required-color: #d74654;
$contrast-required-color: #fefe00;

$default-placeholder-color: #767575;
$dark-placeholder-color: #b5b4b2;
$contrast-placeholder-color: #ffffff;

$default-timeline-color: #e1dfdd;
$dark-timeline-color: #7a7ccf;
$contrast-timeline-color: #ffffff;

$default-border-color: #e1dfdd;
$dark-border-color: #7a7ccf;
$contrast-border-color: #ffffff;

$themes: (
    default: (
        brand: #6264a7,
        card-item-color: #ffffff,
        red-color: #cc4a31,
        background-color: #f3f2f1,
        gray-bgcolor: #f5f5f5,
        toolbar-color: #f0f0f0,
        white-color: #ffffff,
        black-color: #000000,
        primary-text: #252423,
        secondary-text-color: #484644,
        tertiary-text-color: #605e5c,
        timestamp-text-color: #979593,
        disabled-text-color: #b5b4b2,
        borders-color: #e1dfdd,
        primary-button-text-color: #f3f2f1,
        primary-button-text-hover-color: #f3f2f1,
        primary-button-bg-color: #6264a7,
        primary-button-bg-hover-color: #6364a5,
        primary-button-border-color: #f3f2f1,
        disabled-button-text-color: #979593,
        disabled-button-text-hover-color: #979593,
        disabled-button-bg-color: #f3f4f5,
        secondary-button-text-color: #252423,
        secondary-button-text-hover-color: #252423,
        secondary-button-bg-color: #ffffff,
        secondary-button-bg-hover-color: #f3f2f1,
        link-color: #6264a7,
        link-hover-color: #6264a7,
        list-item-menu-fluent-ui-cutom: #ffffff,
        list-item-selected-bgcolor-custom: #ffffff,
        list-item-hover-bgcolor: #ebebeb,
        list-item-focus-bgcolor: #c8c6c4,
        nav-link-bgcolor: #ffffff,
        nav-link-hover-bgcolor: #eaeaea,
        nav-link-selected-bgcolor: #eaeaea,
        nav-link-color: #6264a7,
        nav-link-hover-color: #6264a7,
        nav-link-selected-color: #6264a7,
        nav-menu-border-color: #6264a7,
        top-nav-link-color: #000000,
        top-nav-link-hover-color: #6264a7,
        top-nav-link-selected-color: #6264a7,
        loading-spinner-color: #6264a7,
        color-bar-border-color: #4975c3,
        gray-02: #484644,
        gray-03: #605e5c,
        gray-04: #979593,
        gray-06: #c8c6c4,
        gray-08: #e1dfdd,
        gray-12: #f4f4f4,
        appointments-icon-color-1: #4c92ba,
        appointments-icon-color-2: #6fcf97,
        appointments-icon-color-3: #7a7ccf,
        appointments-icon-color-4: #7a7ccf,
        events-checkedin-color: #237b4b,
        calendar-today-color: #f4f4fc,
        chart-color-one: #8b8cc7,
        chart-color-two: #6264a7,
        chart-color-three: #535c6c,
        chart-color-four: #d9dbdb,
        chart-color-five: #464775,
        chart-color-size: #c4314b
    ),
    dark: (
        brand: #7a7ccf,
        card-item-color: #292929,
        red-color: #d74654,
        background-color: #11100f,
        gray-bgcolor: #1f1f1f,
        toolbar-color: #202020,
        white-color: #000000,
        black-color: #ffffff,
        primary-text: #f3f2f1,
        secondary-text-color: #bebbb8,
        tertiary-text-color: #a19f9d,
        timestamp-text-color: #8a8886,
        disabled-text-color: #ffffff,
        borders-color: #000000,
        primary-button-text-color: #f3f2f1,
        primary-button-text-hover-color: #f3f2f1,
        primary-button-bg-color: #7a7ccf,
        primary-button-bg-hover-color: #6364a5,
        primary-button-border-color: #f3f2f1,
        disabled-button-text-color: #ffffff,
        disabled-button-text-hover-color: #ffffff,
        disabled-button-bg-color: #484644,
        secondary-button-text-color: #ffffff,
        secondary-button-text-hover-color: #ffffff,
        secondary-button-bg-color: #000000,
        secondary-button-bg-hover-color: #252423,
        link-color: #7a7ccf,
        link-hover-color: #7a7ccf,
        list-item-menu-fluent-ui-cutom: #11100F,
        list-item-selected-bgcolor-custom: #3d3d3d,
        list-item-hover-bgcolor: #292929,
        list-item-focus-bgcolor: #6e6d6b,
        nav-link-bgcolor: #000000,
        nav-link-hover-bgcolor: #201f1e,
        nav-link-selected-bgcolor: #201f1e,
        nav-link-color: #ffffff,
        nav-link-hover-color: #7a7ccf,
        nav-link-selected-color: #7a7ccf,
        nav-menu-border-color: #ffffff,
        top-nav-link-color: #ffffff,
        top-nav-link-hover-color: #7a7ccf,
        top-nav-link-selected-color: #7a7ccf,
        loading-spinner-color: #ffffff,
        color-bar-border-color: #2385e8,
        gray-02: #bebbb8,
        gray-03: #a19f9d,
        gray-04: #8a8886,
        gray-06: #484644,
        gray-08: #3b3a39,
        gray-12: #201f1e,
        appointments-icon-color-1: #4c92ba,
        appointments-icon-color-2: #6fcf97,
        appointments-icon-color-3: #7a7ccf,
        appointments-icon-color-4: #7a7ccf,
        events-checkedin-color: #237b4b,
        calendar-today-color: #201f1e,
        chart-color-one: #8b8cc7,
        chart-color-two: #6264a7,
        chart-color-three: #535c6c,
        chart-color-four: #d9dbdb,
        chart-color-five: #464775,
        chart-color-size: #c4314b
    ),
    highcontrast: (
        brand: #9764f5,
        card-item-color: #000000,
        red-color: #fefe00,
        gray-bgcolor: #000000,
        background-color: #000000,
        toolbar-color: #000000,
        white-color: #000000,
        black-color: #000000,
        primary-text: #ffffff,
        secondary-text-color: #fefe00,
        tertiary-text-color: #1aebff,
        timestamp-text-color: #8a8886,
        disabled-text-color: #00ff06,
        borders-color: #ffffff,
        primary-button-text-color: #252423,
        primary-button-text-hover-color: #252423,
        primary-button-bg-color: #ffffff,
        primary-button-bg-hover-color: #fefe00,
        primary-button-border-color: #252423,
        disabled-button-text-color: #ffffff,
        disabled-button-text-hover-color: #ffffff,
        disabled-button-bg-color: #484644,
        secondary-button-text-color: #ffffff,
        secondary-button-text-hover-color: #000000,
        secondary-button-bg-color: #000000,
        secondary-button-bg-hover-color: #fefe00,
        link-color: #ffffff,
        link-hover-color: #ffffff,
        list-item-menu-fluent-ui-cutom: #000000,
        list-item-selected-bgcolor-custom: #1aebff,
        list-item-hover-bgcolor: #292929,
        list-item-focus-bgcolor: #6e6d6b,
        nav-link-bgcolor: #000000,
        nav-link-hover-bgcolor: #fefe00,
        nav-link-selected-bgcolor: #1aebff,
        nav-link-color: #ffffff,
        nav-link-hover-color: #000000,
        nav-link-selected-color: #000000,
        nav-menu-border-color: #ffffff,
        top-nav-link-color: #ffffff,
        top-nav-link-hover-color: #fefe00,
        top-nav-link-selected-color: #1aebff,
        loading-spinner-color: #fefe00,
        color-bar-border-color: #1aebff,
        gray-02: #bebbb8,
        gray-03: #a19f9d,
        gray-04: #8a8886,
        gray-06: #484644,
        gray-08: #3b3a39,
        gray-12: #201f1e,
        appointments-icon-color-1: #4c92ba,
        appointments-icon-color-2: #6fcf97,
        appointments-icon-color-3: #7a7ccf,
        appointments-icon-color-4: #7a7ccf,
        events-checkedin-color: #237b4b,
        calendar-today-color: #201f1e,
        chart-color-one: #8b8cc7,
        chart-color-two: #6264a7,
        chart-color-three: #535c6c,
        chart-color-four: #d9dbdb,
        chart-color-five: #464775,
        chart-color-size: #c4314b
    )
);

//Non-themed colors
$white: #ffffff;
$black: #000000;
$grey: #e1dfdd;
$yellow: #ffb900;
$red: #e83431;
$greypurple: #d1d2f0;
$gold: gold;
$green: #548235;
$l-green: #c5e0b4;
$disabled-text-invert: rgba(255, 255, 255, 0.38);
$event-status-published: #007bcc;
$event-status-cancelled: #e83431;
$event-status-unpublished: #f7aa36;
$event-status-private: #15db12;

//Gradient
$linear-gradient-1: rgba(0, 0, 0, 0);
$linear-gradient-2: rgba(192, 192, 192, 1);

//Box Shadow
$shadow-gradient-1: rgba(0, 0, 0, 0.133);
$shadow-gradient-2: rgba(0, 0, 0, 0.11);

// Font sizes
$fontSizeXXS: 9px !default;
$fontSizeXS: 10px !default;
$fontSizeS: 11px !default;
$fontSize: 12px !default;
$fontSizeMS: 13px !default;
$fontSizeM: 14px !default;
$fontSizeMM: 15px !default;
$fontSizeML: 16px !default;
$fontSizeL: 18px !default;
$fontSizeLL: 24px !default;
$fontSizeLLL: 28px !default;
$fontSizeXL: 36px !default;
$fontSizeXXL: 40px !default;
$fontSizeXXXL: 56px !default;
$fontSizeXXXXL: 72px !default;

/// Heavy (bold) font weight
/// @group core|typography
$fontWeightHeavy: bold !default;

/// Normal font weight
/// @group core|typography
$fontWeightNormal: normal !default;

/// Light (200) font weight
/// @group core|typography
$fontWeightLighter: 200 !default;

/// Semi-bold (600) font weight
/// @group core|typography
$fontWeightSemiBold: 600 !default;

/// 1px
/// @group core|spacing
$spacingBorder: 1px;

/// 3px
/// @group core|spacing
$spacingFocusRect: 3px;

/// spacing-XXS
/// @group core|spacing
$spacing-4: 4px;

/// spacing-XS
/// @group core|spacing
$spacing-8: 8px;

/// spacing-S
/// @group core|spacing
$spacing-12: 12px;

/// spacing-M
/// @group core|spacing
$spacing-16: 16px;

/// spacing-L
/// @group core|spacing
$spacing-20: 20px;

/// @group core|spacing
$spacing-24: 24px;

/// spacing-XL
/// @group core|spacing
$spacing-32: 32px;

/// spacing-XXL
/// @group core|spacing
$spacing-40: 40px;

$radius-medium: $spacing-4;
