@import "_base.scss";

.c-loading-page {
    height: 100vh;
    background: url("./images/microsoft-gradient.svg") 0 0 no-repeat;
    background-size: 100% auto;
    background-position: right;

    .c-loading-card {
        @include background-color("white-color");
        width: 150px;
        height: 100px;
        position: fixed;
        top: 50%;
        left: 50%;
        margin-top: -50px;
        margin-left: -75px;
        padding: 25px 0;
    }
}
