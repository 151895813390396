@import "../../../../styling/base";


.c-link-container {
    .c-copy-button {
        margin-left: 10px;
        min-width: 95px;
    }

    .ms-Label {
        @include color("tertiary-text-color");
    }
}

.c-link-container.bottom {
    margin-top: 10px;
}