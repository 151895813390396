@import "../../../../../styling/base";

.c-card {
    padding: 2vh 2vh 2vh 2vh;
    overflow-y: auto;
    //no box shadow necessary, comes from card component
}

.c-card-header {
    display: flex;
    flex-grow: 1;
    font-size: $teams-title-font-size;
    font-weight: 600;
    flex-flow: row nowrap;
    margin-bottom: 2vh;

    .c-left-icon {
        margin-left: auto;
    }
}

.c-card-body {
    padding: 0vh 0vh 0vh 2vh;

    .c-card-subtitle {
        font-weight: 600;
    }

    .c-card-separator-horizontal {
        margin: 4vh 2vh 4vh 2vh;
        width: 100%;
        min-height: 1px;
        @include background-color("gray-03");
    }

    .c-card-separator-vertical {
        margin: 2vh 4vh 2vh 4vh;
        min-width: 1px;
        @include background-color("gray-03");
        height: 100%;
    }

    .c-card-body-row {
        padding: 0vh 0vh 1vh 0vh;

        .c-card-content-title {
            padding: 0vh 2vh 0vh 0vh;

            .c-demographic-title {
                width: 63px;
            }
        }

        .c-card-content-content {
            flex-grow: 1;
        }
    }

    .c-card-body-column {

        .c-card-content-left-column {
            padding-right: 2vh;
            flex-grow: 1;
        }

        .c-card-content-right-column {
            padding-left: 2vh;
            flex-grow: 1;
        }
    }

    .c-card-content {
        margin-top: 2vh;
        justify-content: flex-start;
    }
}

.c-card-footer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    margin-top: 4vh;

    .c-card-footer-item {
        padding: 0vh 0vh 0vh 2vh;
    }
}
