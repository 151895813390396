@import "../../../../styling/base";
@import "../../../../common//components/AppointmentStatus/AppointmentStatus.scss";

.c-appointment-item {
    @include border-color("borders-color");
    border-radius: 0px;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
    padding: 12px;
    width: 420px;
}

@media screen and (max-width: 420px) {
    .c-appointment-item {
        width: 340px;
        height: 160px;
    }
}

.c-appointment-icon-col {
    padding-right: 8px;
    @include color("appointments-icon-color-3");
    font-size: 16px;
}

.c-appointment-card {
    padding-right: 12px;
    font-size: 14px;
    line-height: 20px;

    .c-appointment-label {
        @include color("tertiary-text-color");
        font-weight: bold;
        min-width: 124px;
        padding-top: 6px;
    }

    .c-appointment-category-label {
        @include color("tertiary-text-color");
        font-weight: bold;
        min-width: 124px;
        padding-top: 12px;
    }

    .c-appointment-category {
        @include color("tertiary-text-color");
        padding-top: 12px;
        text-overflow: ellipsis;
        width: 230px;
        overflow: hidden;
        white-space: nowrap;
    }

    .c-appointment-title {
        font-weight: bold;
        font-size: 15px;
    }

    .c-appointment-customerName {
        @include color("tertiary-text-color");
        padding-top: 4px;

        .ms-Persona-initials {
            color: white; // needs to be white in all modes (light, dark and high contrast) for accessibility
        }

        .c-customer {
            padding-left: 4px;
            padding-top: 4px;
            text-overflow: ellipsis;
            width: 230px;
            overflow: hidden;
            white-space: nowrap;
        }
    }

    .c-appointment-tertiary-text {
        @include color("tertiary-text-color");
        padding-top: 4px;
    }

    .c-appointment-confirmationNumber {
        @include color("tertiary-text-color");
        padding-top: 4px;
    }

    .c-appointment-assignedto {
        @include color("tertiary-text-color");
        padding-top: 4px;
        margin-bottom: 16px;

        .c-assignedto {
            padding-left: 4px;
            text-overflow: ellipsis;
            width: 230px;
            overflow: hidden;
            white-space: nowrap;
        }
    }
}

.c-icon-col {
    height: 24px;
}

.c-warning-icon {
    color: $red;
    font-size: 16px;
    margin-right: 4px;
}