@import "../../../../../../../../styling//base";

.c-spinner-padding {
    padding: 4vh 4vh 4vh 4vh;
    width: 100%;
}

.c-appointmentList {
    width: 100%;

    .c-header {
        margin-bottom: 2vh;

        .c-header-text {
            font-size: $teams-subtitle-font-size;
            font-weight: 600;
        }
    }

    .c-selected-appointment {
        @include background-color("gray-08");
    }
    .c-selected-appointment:hover {
        @include background-color("gray-08");
    }

    .c-appointment-container {
        padding: 1vh 2vh 1vh 2vh;
    }
    .c-appointment-container:hover {
        @include background-color("list-item-hover-bgcolor");
        @include color("top-nav-link-hover-color");
    }

    .c-appointment-status {
        margin-left: auto;
        margin-right: 0;
    }

    .c-appointment-information {
        margin-right: 1vh;
    }

    .bold {
        font-weight: bold;
    }
}