@import "../../../../styling/teams";
@import "../../../../styling/base";

.c-appointment-container {
    display: flex;
    flex-direction: row;
    flex: 1;


    .c-main-container {
        flex: 1.5;
    }

    .c-side-container {
        display: flex;
        flex-direction: column;
        flex: 1;

        .size-1 {
            max-height: 180px;
            flex-grow: 1;
        }

        .size-2 {
            flex-grow: 2;
        }
    }
}