.c-csat-body {
    span {
        display: block;
    }

    span.large {
        font-size: 36px;
    }

    span.inline {
        display: inline;
    }

    span.bold {
        font-weight: 700;
    }

    span.top {
        margin-bottom: 10px;
    }
}