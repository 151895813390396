@import "../../../../../styling/_base.scss";

.c-rr-state-filter {
    @include background-color("toolbar-color");
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    min-width: 216px;
    border-right: 1px solid;

    .c-queue-state-button {
        margin: auto 0 5px 8px;
    }

    .c-state-filters {
        font-size: $teams-base-font-size;
        min-width: 216px;
        padding: 10px 0px 10px 15px;
        text-align: left;
        cursor: pointer;
    }
    
    .c-state-filters:hover {
        @include background-color("list-item-hover-bgcolor");
        @include color("top-nav-link-hover-color");
    }
}