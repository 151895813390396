@import "../../../../../styling/_base.scss";

.c-clienteling-cmdbar {
    .c-hamburger-icon {
        margin: auto 6px auto 8px;
    }

    .c-store-selection {
        margin: auto 4px auto auto;
        @include border-color("borders-color");
        border: 1px solid;
        border-radius: 20px;

        .ms-Button {
            border-radius: 20px;
        }
    }

    .c-filter-button {
        margin: auto 2px auto auto;
    }

    .c-calendar-picker {
        margin: auto 4px auto auto;
        padding-top: 5px;
        min-width: 150px;
    }

    .c-dropdown {
        margin: auto 4px auto auto;
        font-size: 12px;
        line-height: 12px;
    }

    .c-search-button {
        margin: auto 2px auto auto;

        .c-search-box {
            margin: 0;
        }

        button {
            border: none;
        }
    }

    .c-newappt-button {
        margin: auto;
    }
}

.c-command-bar {
    .ms-Button {
        @include background-color("toolbar-color");
    }

    .ms-Button:hover {
        @include background-color("background-color");
        @include color("top-nav-link-hover-color");
    }
}

.ms-DatePicker {
    .ms-TextField-field {
        @include background-color("white-color");
        height: fit-content;
    }
}

.ms-Callout {
    border: 1px solid;
}

.ms-CommandBar {
    padding: 0px;
}