@import "_teams.scss";

/// Themify mixin
/// @access private
/// @author Hugo Giraudel
/// @param {String} $property - Property to themify
/// @param {String} $key - Key color to use from theme
/// @param {Map} $themes [$themes] - Map of themes to use
@mixin themify($property, $key, $themes: $themes) {
    // Iterate over the themes
    @each $theme, $colors in $themes {
        // Create a selector (e.g. `.media.theme-unicorn, .theme-unicorn .media`)
        &.theme-#{$theme},
        .theme-#{$theme} & {
            // Output the declaration
            #{$property}: map-get($colors, $key);
        }
    }
}

////////////////////// HELPER FUNCTIONS FOR THEMES //////////////////////
/////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////
/// Shorthand to themify color through `themify` mixin
/// @access public
/// @see {mixin} themify
@mixin color($arguments...) {
    @include themify("color", $arguments...);
}

/// Shorthand to themify border-color through `themify` mixin
/// @access public
/// @see {mixin} themify
@mixin border-color($arguments...) {
    @include themify("border-color", $arguments...);
}

/// Shorthand to themify background-color through `themify` mixin
/// @access public
/// @see {mixin} themify
@mixin background-color($arguments...) {
    @include themify("background-color", $arguments...);
}

/// Shorthand to themify fill through `themify` mixin
/// @access public
/// @see {mixin} themify
@mixin fill($arguments...) {
    @include themify("fill", $arguments...);
}

/// Shorthand to themify stroke through `themify` mixin
/// @access public
/// @see {mixin} themify
@mixin stroke($arguments...) {
    @include themify("stroke", $arguments...);
}

/// Shorthand to themify outline-color through `themify` mixin
/// @access public
/// @see {mixin} themify
@mixin outline-color($arguments...) {
    @include themify("outline-color", $arguments...);
}

///Themed background colors

.t-brand-bgColor {
    @include background-color("brand");
}

.t-red-bgColor {
    @include background-color("red");
}

.t-background-bgColor {
    @include background-color("background-color");
}

//Themed text colors
.t-brand-color {
    @include color("brand");
}

.t-red-color {
    @include color("red-color");
}

.t-primary-color {
    @include color("primary-text");
}

.t-secondary-color {
    @include color("secondary-text-color");
}

.t-tertiary-color {
    @include color("tertiary-text-color");
}

.t-timestamp-color {
    @include color("timestamp-text-color");
}

.t-disabled-color {
    @include color("disabled-text-color");
}

.t-primary-button {
    @include background-color("primary-button-bg-color");
    @include color("primary-button-text-color");
    @include border-color("primary-button-border-color");
    border: 1px solid;
}

.t-primary-button:hover {
    @include color("primary-button-text-hover-color");
}

.t-primary-button:disabled {
    @include background-color("disabled-button-bg-color");
    @include color("disabled-button-text-color");
}

.t-primary-button:disabled:hover {
    @include color("disabled-button-text-hover-color");
}

.t-default-button {
    @include background-color("secondary-button-bg-color");
    @include color("secondary-button-text-color");
}

.t-default-button:hover {
    @include color("secondary-button-text-hover-color");
}

.t-legal-warning-message {
    @include color("red-color");
}

.t-gray-02-color {
    @include color("gray-02");
}

.t-gray-03-color {
    @include color("gray-03");
}

.t-gray-04-color {
    @include color("gray-04");
}

.t-gray-06-color {
    @include color("gray-06");
}

.t-gray-08-color {
    @include color("gray-08");
}

.t-gray-12-color {
    @include color("gray-12");
}

///Themed border colors
.t-border-color {
    @include border-color("borders-color");
}

///Themed list item colors
.t-list-item-selected {
    @include background-color("list-item-selected-bgcolor-custom");
    @include color("black-color");
}

.t-nav-link-hover-highlighted {
    @include background-color("nav-link-hover-bgcolor");
}

/////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////

.t-base-font-family {
    font-family: $teams-base-font-family;
}

.t-base-font-size {
    font-size: $teams-base-font-size;
}

.t-title-font-size {
    font-size: $teams-title-font-size;
}

.t-header-font-size {
    font-size: $teams-header-font-size;
}

.t-caption-font-size {
    font-size: $teams-caption-font-size;
}

///////////////////////////////////////////
// Add all other themes below with comment for explanation

//From Teams: "Text buttons, icons, and controls are contained within a 32px high container to ensure all controls are visually aligned and consistent."
.t-button-icon-control-container-height {
    height: 32px;
}

//Used for the stand up card view
.t-brand-border-color {
    @include border-color("brand");
}

.t-disabled-button-background-color {
    background-color: #dfdede;
}

///////////////////////////////////////////
////Styles that are not sass generated ////
///////////////////////////////////////////

//To align elements on the right side of the page
.t-right-align {
    display: flex;
    width: 100%;
    justify-content: flex-end;
}

//To align elements on the right side of the page
.t-left-align {
    display: flex;
    width: 100%;
    justify-content: flex-start;
}

//To align elements in the center of the page horizontally
.t-center-horizontal {
    display: flex;
    justify-content: center;
}

///////////////////////////////////////////
////Styles that are not sass generated ////
///////////////////////////////////////////
.t-left-text {
    text-align: left;
}

.t-right-text {
    text-align: right;
}

.t-semi-bold {
    font-weight: 600;
}

//Status colors for the Standups
.t-status-not-started {
    background-color: #be000f;
}

.t-status-in-progress {
    background-color: #117bca;
}

.t-status-ready-delivery {
    background-color: #febf29;
}

.t-status-complete {
    background-color: #197c1a;
}

//Styling to make a dot on the page
.t-dot {
    height: 10px;
    width: 10px;
    border-radius: 50%;
    display: inline-block;
    margin-right: 5px;
}

.visually-hidden {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0px;
    border: 0px;
    overflow: hidden;
}

html,
body {
    height: 100%;
    width: 100%;
}

body {
    margin: 0;
    @include color("primary-text");
    @include background-color("white-color");
    overflow: hidden;
}

a {
    cursor: pointer;
}

#root {
    width: 100%;
    height: 100%;
    overflow: hidden;
}

.c-loading-spinner {
    
}

// All of these mixins have an implied "and below" attacked
@mixin xsmall-screen {
    @media screen and (max-width: 600px) {
        & {
            @content;
        }
    }
}

@mixin small-screen {
    @media screen and (max-width: 1023px) {
        & {
            @content;
        }
    }
}

@mixin medium-screen {
    @media screen and (max-width: 1365px) {
        & {
            @content;
        }
    }
}

@mixin large-screen {
    @media screen and (max-width: 1919px) {
        & {
            @content;
        }
    }
}

@mixin xlarge-screen {
    @media screen and (min-width: 1920px) {
        & {
            @content;
        }
    }
}

@mixin touch-smooth-scrolling {
    // For now, enable touch scrolling only for small devices
    @include xsmall-screen {
        -webkit-overflow-scrolling: touch;
    }
}

.ms-Label {
    @include color("primary-text");
}

.scroll-hidden {
    overflow: hidden;
}

.v-scroll-auto {
    overflow-y: auto;
}

#page {
    display: flex;
    overflow: hidden;
    flex-grow: 1;
}

.ms-Dropdown:hover > .ms-Dropdown-title {
    @include border-color("borders-color");
}

.ms-Callout {
    @include border-color("borders-color");
}

.ms-TextField-fieldGroup:hover {
    @include border-color("borders-color");
}

.ms-SearchBox:hover {
    @include border-color("borders-color");
}
