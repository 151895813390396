@import "../../../../styling/base";

.c-rr-notification-modal {
    border-radius: 4px;
    padding: 24px 32px;

    .c-modal-header {
        .c-icon-cancel {
            margin-left: auto;
        }

        .ms-Label {
            font-size: 20px;
        }
    }

    .c-modal-row {
        justify-content: center;

        .c-modal-image {
            padding: 20px 0px;
        }
    }

    .c-modal-text {
        font-size: 20px;
        font-weight: 600px;
    }

    .c-close-button {
        margin-left: auto;
        margin-top: 24px;
    }
}