@import "../../../../styling/_base.scss";

.c-nav-menu {
    min-width: 200px;
    overflow: "auto";
    @include background-color("white-color");
    @include color("primary-text");
    @include border-color("nav-menu-border-color");
    border: 1px solid;

    .ms-Nav-chevronButton {
        margin: 0;
    }
}
