/* 
 * Flex styles - applied to the flexbox (flex container).
 */

/// display: flex and flex-direction: column
/// @group core|flex-box
.flex-column {
    display: flex;
    flex-direction: column;
}

/// display: flex and flex-direction: row
/// @group core|flex-box
.flex-row {
    display: flex;
    flex-direction: row;
}

/// display: inline-flex and flex-direction: column
/// @group core|flex-box
.inline-flex-column {
    display: inline-flex;
    flex-direction: column;
}

/// display: inline-flex and flex-direction: row
/// @group core|flex-box
.inline-flex-row {
    display: inline-flex;
    flex-direction: row;
}

/// align-items: center
/// @group core|flex-box
.flex-center {
    align-items: center;
}

/// align-items: flex-end
/// @group core|flex-box
.flex-end {
    align-items: flex-end;
    justify-content: flex-end;
}

/// align-items: flex-start
/// @group core|flex-box
.flex-start {
    align-items: flex-start;
}

/// align-items: stretch
/// @group core|flex-box
.flex-stretch {
    align-items: stretch;
}

/// align-items: baseline
/// @group core|flex-box
.flex-baseline {
    align-items: baseline;
}

/// justify-content: flex-start
/// @group core|flex-box
.justify-start {
    justify-content: flex-start;
}

/// justify-content: center
/// @group core|flex-box
.justify-center {
    justify-content: center;
}

/// justify-content: flex-end
/// @group core|flex-box
.justify-end {
    justify-content: flex-end;
}

/// flex-wrap: wrap
/// @group core|flex-box
.flex-wrap {
    flex-wrap: wrap;
}

/* 
 * Flex styles - appled to the flex-items (flexbox children).
 */

/// flex-grow: 1
/// @group core|flex-item
.flex-grow {
    flex-grow: 1;
}

.flex {
    flex: 1;
}

.flex-2 {
    flex: 2;
}

.flex-3 {
    flex: 3
}

.flex-4 {
    flex: 4;
}

.flex-5 {
    flex: 5;
}

.flex-6 {
    flex: 6
}

/// flex-shrink: 0
/// @group core|flex-item
.flex-noshrink {
    flex-shrink: 0;
}

/// align-self: center
/// @group core|flex-item
.flex-self-center {
    align-self: center;
}

/// align-self: flex-end
/// @group core|flex-item
.flex-self-end {
    align-self: flex-end;
}

/// align-self: flex-start
/// @group core|flex-item
.flex-self-start {
    align-self: flex-start;
}

/// align-self: stretch
/// @group core|flex-item
.flex-self-stretch {
    align-self: stretch;
}

/*
 * FlexBox semantic styles.
 */

/// Makes an element a row-based flex box, with items aligned center and no margin/padding.
/// @group core|flex-box
.flex-cell {
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-direction: row;
    margin: 0;
    padding: 0;
    position: relative;
}

.full-size {
    display: flex;
    flex-grow: 1;
    overflow: hidden;
}
