.errorContainer {
    align-items: center;
    justify-content: center;
    display: flex;
    flex-direction: column;
    margin-left: auto;
    margin-right: auto;
    i {
        font-size: 20px;
        color: red;
    }

    span {
        display: block;
        font-weight: 600;
    }
}