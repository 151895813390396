@import "../../../../../styling/base";

.c-dropdown-option.available {
    .ms-Persona-secondaryText {
        color: $green;
    }
}

.c-dropdown-option.unavailable {
    .ms-Persona-secondaryText {
        color: $red;
    }
}