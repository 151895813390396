.c-no-results {
    text-align: center;
    height: 100%;
    width: 100%;

    .c-no-results-img-box {
        margin-left: auto;
        margin-right: auto;
        margin-top: 10%;
        margin-bottom: 32px;

        .ms-Image-image {
            display: inline;
        }
    }

    .c-no-results-text {
        font-size: 20px;
        line-height: 24px;
    }
}
