@import "../../../styling/_base.scss";

.c-rr-cmdbar {
    box-shadow: 0px 1.2px 3.6px rgba(0, 0, 0, 0.03);
    border-top: 1px solid;
    border-bottom: 1px solid;
    @include border-color("borders-color");
    @include background-color("toolbar-color");
    min-height: 48px;

    .c-dropdown {
        margin: auto 6px;
        font-size: 12px;
        line-height: 12px;
    }

    .c-nav-icon {
        margin: auto 10px auto 16px;
    }

    .c-newrr-button {
        margin: auto 12px auto 6px;
    }

    .c-refresh {
        margin: auto 10px;
    }

    .c-search-box {
        margin: auto 6px;
    }
}