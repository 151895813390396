@import "../../../../styling/base";

.c-rr-list {
    display: flex;
    flex: 1;
    flex-wrap: wrap;
    flex-direction: row;
    padding-bottom: 240px;
    max-height: 1000px;
    overflow-y: auto;

    .c-no-requests {
        display: flex;
        flex-grow: 1;
        font-size: 14px;
        font-style: italic;
        justify-content: center;
        padding: 12px;
    }

    .c-rr-list-row {
        font-size: 14px;

        &:hover {
            cursor: pointer;
        }

        .c-description {
            overflow: inherit;
            text-overflow: ellipsis;
        }
    }

    .ms-Viewport {
        flex: 1;
    }
}