@import "../../../styling/_base.scss";

.c-setting-row {
    padding: 16px;

    .c-setting-title {
        @include color("black-color");
        font-weight: 600;
        padding-bottom: 3px;
    }
}
