.c-notes-container {
    padding: 20px;

    .c-header {
        font-weight: 600;
    }

    .c-text-container {
        margin-top: 15px;
    }
}