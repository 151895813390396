@import "../../../styling/base";

.c-pill {
    height: 25px;
    border-radius: 20px;
    padding-left: 10px;
    padding-right: 10px;
    margin-left: 2px;
    margin-right: 2px;
    margin-bottom: 5px;
}

.c-pill.gamer {
    @include background-color("appointments-icon-color-1");
}

.c-pill.student {
    @include background-color("appointments-icon-color-2");
}

.c-pill.highvalue {
    @include background-color("appointments-icon-color-3");
}

.c-pill.adopter {
    @include background-color("appointments-icon-color-4");
}