.c-footer-container {
    justify-content: flex-end;
}

.c-button-cancel {
    margin-right: 8px;
}

.c-type-label {
    margin-top: 24px;
    margin-bottom: 12px;
}

.c-type-checkbox {
    margin-bottom: 12px;
}

.c-store-dropdown {
    margin-top: 24px;
}

.c-choicegroup {
    margin-top: 24px;
}