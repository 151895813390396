@import "../../../../../../../../styling/base";

.c-contact-survey-results {
    margin: 3vh 0vh 0vh 1vh;

    .c-condensed-two-column {
        margin-right: 5vw;
    }

    .c-header {
        font-weight: 600;
        padding-bottom: 0.5vh;
    }

    .c-content {
        padding-bottom: 1.5vh;
    }

    .c-space {
        margin-bottom: 5vh;
    }

    .c-sub-content {
        font-size: $teams-caption-font-size;
    }
}