 @import "../../../../styling/base";

.rooster-wrapper {
    .rooster-editor {
        @include background-color("toolbar-color");
        border-bottom: 2px solid $dark-border-color;
    }

    .rooster-editor.empty {
        max-height: 31px;
    }

    .rooster-command-bar {
        button:not(:hover):not(:active):not(:focus):not(.is-checked) {
            @include background-color("card-item-color");
            i {
                @include color("primary-text");
            }
        }

        button:hover, button:active, button:focus {
            @include background-color("card-item-color");
            i {
                @include color("brand");
            }
        }

        button.is-checked {
            @include background-color("card-item-color");
            i {
                @include color("brand");
            }
        }

        .ms-CommandBar {
            @include background-color("card-item-color");
        }
    }
}

.rooster-command-button-root {
    @include background-color("toolbar-color");

    button {
        @include background-color("toolbar-color");
        i {
            @include color("primary-text");
        }
    }
}

.rooster-callout {
    span {
        @include color("primary-text");
    }
    .ms-ContextualMenu-link:hover, .ms-ContextualMenu-link:active, .ms-ContextualMenu-link:focus {
        span {
            @include color("brand");
        }
    }
}