.c-add-modal {
    min-width: 520px;
    align-items: stretch;
    padding: 32px 32px 32px 32px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 8px 16px rgba(0, 0, 0, 0.14);
    border-radius: 4px;

    .c-header-label {

        .ms-Label {
            font-size: 20px;
        }
    }

    .c-icon-cancel {
        margin-left: auto;
    }

    .c-back-button {
        margin-right: 12px;
    }

    .c-firstrow {
        margin-left: 20px;
    }

    .c-row {
        margin-top: 16px;
        min-width: 520px;
        max-width: 630px;
        width: 100%;
    }

    .c-column-1 {
        min-width: 200px;
        margin-right: 12px;
        width: 100%;
    }

    .c-column-2 {
        min-width: 288px;
        width: 100%;
    }

    .c-column-wide {
        min-width: 500px;
        width: 100%;
    }

    .c-footer-container {
        justify-content: flex-end;
        margin-top: 80px;
    }
}
