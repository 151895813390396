﻿.c-status-inprogress,
.c-status-scheduled,
.c-status-noshow,
.c-status-junk,
.c-status-outofarea,
.c-status-outofscope,
.c-status-staffing,
.c-status-techsupport {
    font-weight: 600;
    @include color("tertiary-text-color");
}

.c-status-cancelled {
    font-weight: 600;
    @include color("tertiary-text-color");
}

.c-status-completed {
    font-weight: 600;
    @include color("tertiary-text-color");
}

.c-status-checkedin {
    font-weight: 600;
    @include color("events-checkedin-color");
}

.c-status-waiting {
    font-weight: 600;
    @include color("red-color");
}

.c-timeexceeded-end-time {
    font-weight: 600;
    @include color("red-color");
}

.c-timewithin-end-time {
    font-weight: 600;
    color: $green;
}