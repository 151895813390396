.c-contactNotes {
    flex: 2;

    .ms-Spinner {
        height: 100%;
    }

    .c-notes-container {
        padding-top: 30px;
        padding-left: 30px;

        .c-header {
            font-weight: 600;
        }
    }

    .c-notes {
        margin-left: 30px;
        margin-right: 30px;

        .c-note {
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }

    .c-create-note-padding {
        padding-bottom: 20px;
    }
}