@import "../../../../styling/base";

.c-details-list {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    padding-bottom: 240px;
    max-height: 1000px;
    overflow-y: auto;
    flex-grow: 1;

    .ms-Viewport {
        width: 100%;
    }

    .c-listitem-time {
        font-size: 14px;
    }

    .c-listitem-type {
        font-size: 14px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .c-listitem-category {
        font-size: 14px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .c-listitem-status {
        font-size: 14px;
    }

    .c-assignedto {
        padding-left: 4px;
        padding-top: 4px;
        font-size: 14px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }

    .c-unassigned-text {
        font-size: 14px;
    }
}

.c-details-list.not-found {
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .ms-Image {
        margin-top: 100px;
    }

    span {
        margin-top: 10px;
        font-weight: 400;
    }
}

.ms-DetailsHeader {
    padding-top: 4px;
}
