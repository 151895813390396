

.c-contactDetails {
    padding-left: 20px;
    flex: 1;

    .c-persona-container {
        margin-right: auto;
        margin-top: 20px;
    }

    .c-info-container {
        margin-top: 30px;
        .c-labels {
            span {
                display: block;
                font-weight: 600;
                margin-bottom: 10px;
            }
        }

        .c-values {
            margin-left: 20px;
            span {
                display: block;
                margin-bottom: 10px;
            }
        }
    }
}