.c-field.left {
    margin-right: 5px;
}

.c-field.right {
    margin-left: 5px;
}

.c-lead {

    .ms-TextField, .ms-Dropdown-container {
        margin-top: 10px;
    }
}