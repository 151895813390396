@import "../../../../../../../../../../styling/base";

.c-note-container {

    .c-persona {
        margin-top: 7px;
        max-width: 40px;
    }


    .c-note-header {
        font-weight: 600;
    }

    .c-note-info {
        @include color("secondary-text-color");
        margin-left: 10px;
    }

    .c-dropdown-menu {
        position: relative;
        top: -10px;
    }

    i[data-icon-name=ChevronDown] {
        display: none;
    }

    .c-edit-controls {
        margin-left: 10px;

        i {
            font-size: large;
        }
    }
}