@import "../../../styling/_base.scss";

.c-appointment-commandBar {
    @include background-color("toolbar-color");
    @include border-color("borders-color");
    box-shadow: 0px 1.2px 3.6px rgba(0, 0, 0, 0.03);
    border-top: 1px solid;
    border-bottom: 1px solid;

    .c-reservations-button {
        margin: auto 4px auto 12px;
    }

    .c-appointment-title {
        font-weight: bold;
        margin: auto 8px auto auto;
    }

    .c-button-container {
        align-items: center;
        justify-content: center;
    }

    .c-primary-button {
        float: right;
        margin: auto 12px auto auto;
    }

    .c-vertical-separator {
        @include border-color("borders-color");
        border-left: 2px solid;
        margin: auto 4px auto auto;
        height: 28px;
    }

    .c-link {
        margin: auto 4px auto auto;
        
        img {
            max-width: 20px;
            max-height: 20px;
            margin-right: 5px;
        }
    }

    .c-appointment-opencase-icon {
        padding-right: 8px;
        @include color("appointments-icon-color-3");
        font-size: 16px;
    }

    .c-appointment-opencase-text {
        padding-bottom: 2px;
    }
}
