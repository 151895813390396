.c-clienteling-cmdbar {
    .c-nav-item {
        margin: auto 4px;

        .c-search-box {
            min-width: 300px;
        }
    }

    .c-nav-item.left-item {
        margin: auto 4px auto 12px;
    }
}