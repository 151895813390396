@import "src/styling/_teams.scss";
@import "src/styling/_base.scss";

// Page layout

$pageMaxWidth: 1366px;
$pageMargin: 10px;
$pageColumnGap: 0;
$pageRowGap: 0;

#page {
    margin: 0;
    @include background-color("white-color");
    overflow: hidden;
    width: 100%;
    height: 100vh;
}

.c-page {
    padding: 0;
    @include background-color("gray-bgcolor");
}

.c-mobile-page {
    overflow: auto;
}

.c-footer {
    padding: 0;
}

.skip-link {
    display: block;
    overflow: hidden;

    &:not(:focus) {
        height: 0px;
    }
}
