@import "../../../../styling/base";

.c-enable-disable-modal {
    border-radius: 4px;
    display: flex;
    width: 600px;
    padding: 24px 32px;

    .c-modal-header {
        .c-icon-cancel {
            margin-left: auto;
        }

        .ms-Label {
            font-size: 20px;
        }
    }

    .c-modal-row {
        justify-content: center;
    }

    .c-modal-message {
        font-size: 20px;
        font-weight: 600px;
        padding: 36px 16px;
        text-align: center;
    }

    .c-accept-btn {
        margin-left: auto;
        margin-top: 24px;
    }

    .c-scrollable-container {
        display: flex;
    }

    .c-spinner {
        margin: auto;
    }
}