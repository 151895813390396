@import "../../../styling/_base.scss";

.c-feedback-icon,
.c-settings-icon {
    vertical-align: bottom;

    .ms-Button-icon {
        @include color("top-nav-link-color");
    }

    .ms-Button:hover {
        @include background-color("background-color");
        @include color("top-nav-link-hover-color");
    }

    .ms-Button-icon:hover {
        @include background-color("background-color");
        @include color("top-nav-link-hover-color");
    }
}

.c-logo-color-bkgrd {
    @include background-color("brand");
    margin: 6px;

    .c-logo-only-image {
        width: 27px;
        height: 22px;
        padding: 5px;
        padding-bottom: 0;
    }
}

.c-skinny-nav {
    .c-logo-color-bkgrd {
        text-align: center;
        display: inline-block;
        margin: 0 6px 0 0;

        .c-logo-only-image {
            padding: 3px;
            padding-bottom: 0;
        }
    }
    .c-my-store {
        @include color("primary-text");
        display: inline-block;
        font-size: 18px;
        font-weight: 600;
        vertical-align: top;
        margin-top: 4px;
        margin-left: 2px;
    }
}

.c-navbar {
    width: 100%;
    font-size: 14px;
    padding-bottom: 0;
    @include background-color("background-color");

    .ms-CommandBar {
        @include background-color("background-color");
    }

    .c-my-store {
        padding-top: 8px;
        font-size: 18px;
        font-weight: 600;
    }

    .c-nav-item-group {
        display: flex;
        width: 100%;
    }

    button.is-selected .ms-Button-flexContainer {
        font-weight: 600;
        padding-top: 2px;
    }

    .c-top-nav-row {
        flex-grow: 1;
        margin-top: 3px;
    }

    .c-mobile-nav-row {
        flex-grow: 1;
    }
}
