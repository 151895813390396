@import "../../../../styling/base.scss";

.bolt-toast {
    border-radius: 4px;
    margin-bottom: 16px;
    pointer-events: none;

    @include small-screen {
        margin-bottom: 8px;
    }
}

@keyframes slideInFromBottom {
    0% {
        opacity: 0;
        transform: translate(0px, 40px);
    }

    100% {
        opacity: 1;
        transform: translate(0px, 0px);
    }
}

@keyframes slideOutToBottom {
    0% {
        opacity: 1;
        transform: translate(0px, 0px);
    }

    100% {
        opacity: 0;
        transform: translate(0px, 40px);
    }
}

.bolt-toast-content {
    width: 356px; // Const width on most screens

    @include xsmall-screen {
        width: auto;
        flex-grow: 1;
        margin-left: 8px;
        margin-right: 8px;
    }

    @include background-color("gray-02");
    border-radius: 4px;
    padding: 8px 20px;

    pointer-events: auto;

    animation: slideInFromBottom 0.5s;

    &.fade-out {
        animation: slideOutToBottom 0.5s forwards;
    }

    &.one-line {
        .bolt-toast-message:not(:last-child) {
            margin-right: 20px;
        }

        .bolt-toast-separator {
            width: 1px;
            margin-right: 8px;
        }

        .bolt-toast-call-to-action-container {
            align-items: center;
        }
    }

    &.multi-line {
        // Don't need :not(:last-child) because this scenario should never trigger with this by itself
        .bolt-toast-message {
            margin-bottom: 8px;
        }

        .bolt-toast-separator {
            height: 1px;
            margin-bottom: 8px;
        }

        .bolt-toast-call-to-action-container {
            justify-content: flex-end;
        }
    }
}

.bolt-toast-message {
    min-height: 32px;
    display: inline-flex;
    align-items: center;

    /* @NOTE: List break-all first and break-word second, IE11/Firefox dont support break-word, they will fallback to break-all */
    word-break: break-all;
    word-break: break-word;
    @include color("white-color");
}

.bolt-toast-separator {
    background-color: $disabled-text-invert;
    justify-content: stretch;
}

.bolt-toast-call-to-action-container {
    display: inline-flex;
    margin-right: -12px;
}

.bolt-button.bolt-toast-call-to-action {
    @include color('primary-text');
    white-space: nowrap;

    &:hover {
        background-color: transparent;
    }
}
