.c-editor-container {
    margin-left: 10px;
    margin-right: 10px;

    .c-persona {
        max-width: 32px;
        margin-right: 10px;
    }
    .c-editor {
        margin-right: 20px;
    }

    .c-save-link {
        margin-bottom: auto;
        
        i {
            font-size: large;
        }
    }
}