@import "../../../../styling/base";


.c-flex-container {
    @include background-color("gray-bgcolor");
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    overflow: auto;
    padding-bottom: 240px;
    max-height: 1000px;
}

.c-flex-container.not-found {
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .ms-Image {
        margin-top: 100px;
    }

    span {
        margin-top: 10px;
        font-weight: 400;
    }
}

/* Responsive layout - makes a one column layout instead of a two-column layout */
@media screen and (max-width: 420px) {
    .c-flex-container {
        flex-direction: column;
        flex-wrap: nowrap;
    }
}

.c-card-item {
    @include background-color("card-item-color");
    @include border-color("borders-color");
    margin: 8px;
    border-radius: 4px;
    height: fit-content;
}

.c-list-item.c-list-item-selected {
    border: 2px solid;
    @include border-color("black");
}
