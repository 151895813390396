@import "../../../../../../styling/base";

.c-appointment-details {
    padding: 15px;

    .c-row {
        margin-top: 15px;

        .c-form-item {
            margin-top: 10px;
        }

        .c-flex-fill {
            flex: 4;
        }
    }

    .c-status-item {
        float: right;
        margin-left: auto;
        justify-content: right;
        padding-top: 6px;
    }

    .c-edit {
        margin-left: 25px;
    }

    .grey-text {
        @include color("tertiary-text-color");

        .ms-Checkbox-text {
            @include color("tertiary-text-color"); 
        }
    }

    .c-persona {
        margin-top: 10px;
    }

    .c-header {
        font-weight: 600;
    }

    h5 {
        margin-bottom: 0px;
    }

    span {
        display: block;
    }
}
