.c-coming-soon {
    text-align: center;
    height: 100%;

    .c-coming-soon-img-box {
        margin-top: 80px;
    }

    .c-coming-soon-text {
        margin-top: 30px;
        font-size: 20px;
        line-height: 23px;
    }
}
