@import "../../../styling/base";

.c-create-modal {
    min-width: 620px;
    min-height: 46.5rem;
    align-items: stretch;
    overflow-y: auto;
    padding: 32px 32px 32px 32px;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 8px 16px rgba(0, 0, 0, 0.14);
    border-radius: 4px;

    .c-header-label {
        .ms-Label {
            font-size: 20px;
        }
    }

    .c-icon-cancel {
        margin-left: auto;
    }

    .c-footer-container {
        justify-content: flex-end;
        margin-top: 55px;
    }

    .c-column-1 {
        flex-shrink: 1;
        min-width: 285px;
        max-width: 300px;
        width: 100%;
        margin-right: 12px;
    }

    .c-column-2 {
        min-width: 285px;
        max-width: 300px;
        width: 100%;
    }

    .c-column-wide {
        padding: 0px 1px 0px 1px;
        min-width: 580px;
        width: 100%;
    }

    .c-row {
        margin-top: 22px;
        width: 100%;
    }

    .c-createEdit-modal-column {
        display: flex;
        flex-direction: column;
        flex-basis: 100%;
        max-width: 630px;
    }

    .c-custom-responses {
        padding-left: 40px;
    }

    .c-title.bottom {
        font-weight: 900;
    }

    .c-required {
        color: $red;
    }
}
