@import "../../styling/_base.scss";

.c-appointments-list {
    .c-list-col {
        width: 370px;
        min-width: 370px;
        @include background-color("background-color");

        .c-appointments-title {
            display: flex;
            padding: 6px 12px;
            font-size: 12px;
            line-height: 12px;
            @include border-color("borders-color");
            @include background-color("white-color");

            .c-title {
                padding: 8px 0;
                flex-direction: column;
                flex-grow: 1;
            }

            .c-filter {
                flex-direction: column;

                .ms-Button {
                    background-color: transparent;
                    @include color("primary-text");
                    padding: 0px;
                    border: 0;
                    margin: 0;
                    min-width: 0;
                }
            }
        }
    }

    .c-details-col {
        border-left: 2px solid;
        @include border-color("borders-color");
        @include background-color("background-color");
        min-height: 95vh;
    }
}

.c-footer-row {
    @include background-color("background-color");
}

.c-footer-icon {
    border-radius: 50%;
    width: 40px;
    height: 40px;
    font-size: 14px;
    display: inline-block;
    text-align: center;
    padding: 10px;
    line-height: 1.2em;
    @include border-color("brand");
    @include background-color("brand");
    border: 1px solid;
    margin: 0 10px 10px 0;

    i {
        padding-top: 6px;
        font-size: 16px;
        @include color("white-color");
    }
}

.is-flex-state-filter {
    display: flex;

    .c-appointment-container {
        overflow-x: overlay;
    }
}