.c-contactAppointments {
    flex: 1.5;

    .ms-Spinner {
        height: 100%;
    }

    .c-appointments-container {
        padding-top: 30px;
        padding-left: 30px;

        .c-header {
            font-weight: 600;
        }
    }

    .c-appointments {
        margin-left: 30px;
        margin-right: 30px;

        .c-appointment {
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }
}