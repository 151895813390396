@import "../../../styling/_base.scss";

.c-contact-commandBar {
    @include background-color("toolbar-color");
    @include border-color("borders-color");
    box-shadow: 0px 1.2px 3.6px rgba(0, 0, 0, 0.03);
    border-top: 1px solid;
    border-bottom: 1px solid;

    .c-hamburger-icon {
        margin: auto 6px auto 8px;
    }

    .c-reservations-button {
        padding-left: 8px;
        margin-top: auto;
        margin-bottom: auto;
    }

    .c-appointment-title {
        margin-top: auto;
        margin-bottom: auto;
    }

    .c-contact-name {
        font-weight: bold;
        margin-top: auto;
        margin-bottom: auto;
    }
}
