@import "../../../styling/_base";

.c-card-container {
    flex: 1;
    margin: 10px;
    @include border-color("borders-color");
    @include background-color("card-item-color");
    border: 1px solid;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.14);
    border-radius: 7px;
    overflow-y: overlay;
}