@import "./../../../styling/_base.scss";

.c-appointment-buttons {
    .c-appointment-button {
        margin-right: 12px;
        font-size: 14px;
    }

    .ms-Button {
        @include border-color("border-color");
        border-radius: 4px;
        min-width: 0px;
    }

    .ms-Button-label {
        font-family: $teams-base-font-family;
        font-weight: normal;
        text-align: left;
    }
}

.c-modal {
    display: flex;
    min-width: 398px;
    align-items: stretch;
    box-shadow: 0px 0px 2px rgba(0, 0, 0, 0.12), 0px 8px 16px rgba(0, 0, 0, 0.14);
}

.c-modal-container {
    display: flex;
    padding: 28px 32px 32px;
    overflow-y: auto;
    flex-flow: column;
}

.c-modal-header {
    display: flex;
    font-size: $teams-title-font-size;
    font-weight: 600;
    flex-flow: row nowrap;
    margin-bottom: 2vh;

    .c-icon-cancel {
        margin-left: auto;
    }
}

.c-modal-body {


    .c-modal-body-column {
        display: flex;
        flex-flow: row nowrap;

        .c-modal-content-left-column {
            margin-right: 10px;
            flex-grow: 1;
        }

        .c-modal-content-right-column {
            margin-left: 10px;
            flex-grow: 1;
        }
    }

    .c-modal-content {
        margin-top: 2vh;
        justify-content: flex-start;
    }
}

.c-modal-footer {
    display: flex;
    flex-flow: row nowrap;
    justify-content: flex-end;
    margin-top: 4vh;


    .c-modal-footer-item {
        padding: 0px 0px 0px;
        
    }

    .c-modal-button {
        border-radius: 4px;
    }

}