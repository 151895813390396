@import "../../../../../styling/_base.scss";
.c-state-filter-location {
    @include background-color("toolbar-color");
    min-width: 216px;
    height: 100vh;
    border-right: 1px solid;
}
.c-state-filters {
    font-size: $teams-subtitle-font-size;
    min-width: 216px;
    padding: 10px 0px 10px 15px;
    text-align: left;
    cursor: pointer;
}
.c-state-filters:hover {
    @include background-color("list-item-hover-bgcolor");
    @include color("top-nav-link-hover-color");
}
