@import "../../../../../../../../styling/base";

.c-appointment-container {
    .c-appointment-title {
        font-weight: 600;
        max-width: 21vw;
    }

    .c-appointment-info {
        @include color("secondary-text-color");
    }

    .c-appointment-info.right {
        text-align: right;
        font-weight: 600;
    }

    .c-icon-container {
        .c-icon {
            height: 32px;
            width: 32px;
            border-radius: 32px;
            margin-top: 12px;
            margin-right: 10px;
            @include background-color("brand");
            align-items: center;
            justify-content: center;
    
            i {
                margin-left: 9px;
                margin-top: 5px;
                color: $white;
            }
        }
    }
}