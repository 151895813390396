.bolt-condensed-horizontal-spacing {
    padding-left: 20px;
    padding-right: 20px;
}

.bolt-default-horizontal-spacing {
    padding-left: 32px;
    padding-right: 32px;
}

.bolt-relaxed-horizontal-spacing {
    padding-left: 16px;
    padding-right: 16px;
}
